import React, { FC, useEffect, useState } from 'react';
import { Grid, Modal } from 'antd';
import Link from 'next/link';
import { ButtonPrimary } from '@components/Button';
import { Trans, useTranslation } from 'next-i18next';
import { useModalComplete } from '@context/provider/modal';
import { deactivateModalComplete } from '@context/action/modal';
import { ModalCompleteType } from '@models/modal';
import classNames from 'classnames';
import { useRouter } from 'next/router';

const { useBreakpoint } = Grid;

const ModalComplete: FC<ModalCompleteType> = ({ title, subtitle, buttonProps = { text: '', path: '' }, className }) => {
  const screens = useBreakpoint();
  const [widthModal, setWidthModal] = useState(788);
  const { isVisibleModalComplete, dispatch } = useModalComplete();
  const { t } = useTranslation();

  const router = useRouter();

  const isHomepage = router.pathname === '/';

  useEffect(() => {
    if (screens.sm) {
      setWidthModal(608);
    }
    if (screens.xxl) {
      setWidthModal(788);
    }
    if (screens.xs) {
      setWidthModal(327);
    }
  }, [screens]);

  return (
    <div className="modals">
      <Modal
        centered
        visible={isVisibleModalComplete}
        width={widthModal}
        footer={null}
        closeIcon={
          <span onClick={() => dispatch(deactivateModalComplete())}>
            <img src="/icons/icon-close.svg" alt="Close" className="icon-close" />
          </span>
        }
        className={classNames('modal-complete', className)}
      >
        <img src="/icons/icon-checked.svg" alt="Checked" className="icon-checked" />
        <div className="title">
          <span>{title || t('home.titleModal')}</span>
        </div>
        <div className="subtitle">
          <span>{subtitle || <Trans i18nKey={'home.subModal'} />}</span>
        </div>
        {!isHomepage && (
          <div className="button-homepage">
            <Link href={{ pathname: buttonProps.path || '/' }}>
              <a>
                <ButtonPrimary onClick={() => dispatch(deactivateModalComplete())}>
                  {buttonProps.text || t('home.txtExit')}
                </ButtonPrimary>
              </a>
            </Link>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ModalComplete;
